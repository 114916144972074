import Button from "ui/Button";
import Skeleton from "ui/Skeleton";

import Title from "components/Title";
import ProductCard from "components/ProductCard";
import SkeletonWrapper from "components/SkeletonWrapper";
import ProductSelectActions from "components/ProductSelectActions";
import ComponentFallbackRenderer from "components/ComponentFallbackRenderer";

import CommonTemplate from "templates/CommonTemplate";

import TemplateSelect from "pages/PlaylistCreate/components/TemplateSelect";
import Card from "pages/PlaylistCreate/Default/components/Card";
import { STEPS } from "pages/PlaylistCreate/Default/helpers";

const PRODUCT_LIMIT = 8;

const ListSelectedProducts = ({
  fields,
  register,
  errors,
  headerProps,
  template,
  isLoading,
  onDelete,
  onScan,
  onAdd,
  onChangeStep,
  onSubmit,
}) => {
  const countProducts = fields.length;
  const isDisabled = countProducts >= PRODUCT_LIMIT;

  return (
    <CommonTemplate headerProps={headerProps}>
      <form
        onSubmit={onSubmit}
        className="flex flex-1 flex-col justify-between gap-2 px-4 py-6"
      >
        <div className="flex flex-1 flex-col gap-12">
          <div className="flex flex-col gap-4">
            <SkeletonWrapper condition={isLoading}>
              <Title size="xl" className="font-normal">
                Selected Template
              </Title>
            </SkeletonWrapper>

            <SkeletonWrapper
              condition={isLoading}
              skeletonProps={{ width: "100%" }}
              Component={Skeleton.Round}
            >
              <TemplateSelect
                id={template?.id}
                name={template?.name}
                img={template?.img}
                onChangeTemplate={() => onChangeStep(STEPS.TEMPLATES_CATALOG)}
              />
            </SkeletonWrapper>
          </div>

          <div className="flex flex-col gap-4">
            <Title size="xl" className="font-normal">
              Add product to playlist
            </Title>
            <ProductSelectActions
              disabled={isDisabled}
              onScan={onScan}
              onSelect={onAdd}
            />
          </div>

          <div className="flex flex-1 flex-col gap-4">
            <ComponentFallbackRenderer
              data={fields}
              alt={() => (
                <div className="flex flex-1 items-center justify-center text-base-500">
                  Add at least 1 product to create playlist
                </div>
              )}
            >
              {(data) => (
                <>
                  <Title size="xl" className="font-normal">
                    Products added {""}
                    <span className="text-base-300">
                      ({countProducts}/{PRODUCT_LIMIT})
                    </span>
                  </Title>
                  <div className="flex flex-col gap-4">
                    {data.map(({ id, img, name, brand, variant }, index) => (
                      <Card
                        key={id}
                        index={index + 1}
                        onClick={() => onDelete(index)}
                      >
                        <ProductCard
                          img={img}
                          name={name}
                          brand={brand}
                          variant={variant}
                          priceInputProps={{
                            ...register(`products.${index}.price`),
                            helperText:
                              errors.products?.[index]?.price?.message,
                            error: !!errors.products?.[index]?.price,
                          }}
                        />
                      </Card>
                    ))}
                  </div>
                </>
              )}
            </ComponentFallbackRenderer>
          </div>
        </div>
        <Button
          variant="contained"
          fullWidth
          classes={{ root: "sticky bottom-6" }}
          type="submit"
          disabled={!countProducts}
        >
          Save Playlist
        </Button>
      </form>
    </CommonTemplate>
  );
};

export default ListSelectedProducts;
