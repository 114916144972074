import { useEffect, useState } from "react";

/**
 * Custom hook to check if any parent element of a given child element has a specific data attribute.
 * @param {HTMLElement} childElement - The starting child element to check upward.
 * @param {string} dataAttribute - The data attribute to check for (e.g., 'data-main').
 * @returns {boolean} - True if the attribute is found in any parent, otherwise false.
 */
const useCheckDataAttribute = (childElement, dataAttribute) => {
  const [hasAttribute, setHasAttribute] = useState(false);

  useEffect(() => {
    const checkAttribute = () => {
      if (!childElement || !dataAttribute) {
        setHasAttribute(false);
        return;
      }

      let currentElement = childElement;

      // Traverse up the DOM tree to check for the data attribute
      while (currentElement) {
        if (currentElement.hasAttribute(dataAttribute)) {
          setHasAttribute(true);
          return;
        }
        currentElement = currentElement.parentElement;
      }

      // If no parent has the attribute, set the state to false
      setHasAttribute(false);
    };

    checkAttribute();

    // Observe for dynamically added elements
    const observer = new MutationObserver(checkAttribute);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, [childElement, dataAttribute]);

  return hasAttribute;
};

export default useCheckDataAttribute;
