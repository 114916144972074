import { useEffect, useRef, useState } from "react";

export const useLongPress = ({
  onLongPress,
  onCancel,
  delay = 500,
  vibrationPattern = [200],
}) => {
  const [isLongPress, setIsLongPress] = useState(false);
  const timeoutRef = useRef(null);
  const isPressingRef = useRef(false);

  const startPress = () => {
    setIsLongPress(false);
    isPressingRef.current = true;

    timeoutRef.current = setTimeout(() => {
      if (isPressingRef.current) {
        setIsLongPress(true);
        onLongPress?.();

        if (navigator.vibrate) {
          navigator.vibrate(vibrationPattern);
        }
      }
    }, delay);
  };

  const endPress = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    if (isPressingRef.current && !isLongPress) {
      onCancel?.();
    }

    isPressingRef.current = false;
  };

  const handlers = {
    onMouseDown: startPress,
    onMouseUp: endPress,
    onMouseLeave: endPress,
    onTouchStart: startPress,
    onTouchEnd: endPress,
    onTouchCancel: endPress,
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return { handlers, isLongPress };
};

export default useLongPress;
