import { useLocation, useNavigate } from "react-router-dom";

import { SEARCH } from "constants/searchParams";

const useDeleteParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (deleteParams) => {
    if (!deleteParams && deleteParams !== "") return;

    const params = new URLSearchParams(location.search);

    if (deleteParams === true) {
      params.forEach((_, key) => params.delete(key));
    }

    if (typeof deleteParams === SEARCH) {
      params.delete(deleteParams);
    }

    if (Array.isArray(deleteParams)) {
      deleteParams.forEach((param) => params.delete(param));
    }

    navigate(
      { pathname: location.pathname, search: params.toString() },
      { replace: true }
    );
  };
};

export default useDeleteParams;
