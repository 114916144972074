import { useEffect } from "react";

const useBlockScroll = (isActive = true) => {
  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none";
      document.body.style.overscrollBehavior = "none";
      document.body.style.webkitOverflowScrolling = "touch";
    } else {
      document.body.style.overflow = "";
      document.body.style.touchAction = "";
      document.body.style.overscrollBehavior = "";
      document.body.style.webkitOverflowScrolling = "";
    }

    return () => {
      document.body.style.overflow = "";
      document.body.style.touchAction = "";
      document.body.style.overscrollBehavior = "";
      document.body.style.webkitOverflowScrolling = "";
    };
  }, [isActive]);
};

export default useBlockScroll;
