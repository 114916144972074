import { api } from "services/api";

const BASE_URL = "playlists";

export const playlistsApi = api.injectEndpoints({
  endpoints: (build) => ({
    createPlaylist: build.mutation({
      query: (data) => ({
        url: BASE_URL,
        method: "POST",
        body: data,
      }),
    }),
    createDefaultPlaylist: build.mutation({
      query: (data) => ({
        url: `${BASE_URL}/default`,
        method: "POST",
        body: {
          ads: data,
        },
      }),
    }),
    approvePlaylist: build.mutation({
      query: ({ playlistId, name = "Instant Playlist" }) => ({
        url: `${BASE_URL}/approve`,
        method: "PUT",
        body: {
          playlist_id: playlistId,
          name,
        },
      }),
      invalidatesTags: () => [{ type: "Playlists", id: "LIST" }],
    }),
    assignPlaylist: build.mutation({
      query: ({ playlistId, deviceId }) => ({
        url: `${BASE_URL}/${playlistId}/${deviceId}`,
        method: "PUT",
      }),
    }),
    changeStatusPlaylist: build.mutation({
      query: ({ playlistId, deviceId, status = false }) => ({
        url: `${BASE_URL}/active`,
        method: "PUT",
        body: {
          playlist_id: Number(playlistId),
          device_id: Number(deviceId),
          active: status,
        },
      }),
    }),
    deletePlaylist: build.mutation({
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [{ type: "Playlists", id: "LIST" }],
    }),
    deletePlaylistFromDevice: build.mutation({
      query: ({ id, deviceId }) => ({
        url: `${BASE_URL}/delete-with-dewice/${deviceId}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [{ type: "Playlists", id: "LIST" }],
    }),
    updatePlaylist: build.mutation({
      query: ({ id, data }) => ({
        url: `${BASE_URL}/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    getPlaylists: build.query({
      query: ({ page = 1, limit = 10, search = "", device } = {}) => {
        const params = { page, limit, search };

        // if (device !== undefined) {
        //   params.device = device;
        // }

        return {
          url: BASE_URL,
          params: {
            page,
            limit,
            search,
            device,
          },
        };
      },
      keepUnusedDataFor: 0,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 1 || !arg.page) {
          currentCache.data = newItems.data;
        } else {
          currentCache.data.push(...newItems.data);
        }
        currentCache.page = newItems.page;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg.search !== previousArg?.search ||
          currentArg.page !== previousArg?.page
        );
      },
      transformResponse: ({ data, ...response }) => {
        return {
          data: data.map(
            ({ is_default, is_draft, video_url, ...playlist }) => ({
              isDefault: is_default,
              isDraft: is_draft,
              videoUrl: video_url,
              ...playlist,
            })
          ),
          ...response,
        };
      },
      providesTags: [{ type: "Playlists", id: "LIST" }],
    }),
    getPlaylist: build.query({
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
      }),
    }),
    getPlaylistProgress: build.query({
      query: () => ({
        url: `${BASE_URL}/status/process`,
      }),
    }),
    assignDevicesToPlaylist: build.mutation({
      query: ({ playlistId, data }) => ({
        url: `${BASE_URL}/assign/${playlistId}/array`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: () => [{ type: "Stores", id: "LIST" }],
    }),
  }),
});

export const {
  useCreatePlaylistMutation,
  useCreateDefaultPlaylistMutation,
  useApprovePlaylistMutation,
  useAssignPlaylistMutation,
  useAssignDevicesToPlaylistMutation,
  useChangeStatusPlaylistMutation,
  useDeletePlaylistMutation,
  useDeletePlaylistFromDeviceMutation,
  useGetPlaylistsQuery,
  useGetPlaylistQuery,
  useUpdatePlaylistMutation,
  useGetPlaylistProgressQuery,
  // useGetPlaylistQuery,
  // useGetPlaylistsByCategoryQuery,
} = playlistsApi;
