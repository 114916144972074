import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import useAuth from "hooks/useAuth";
import useAddDataAttributeToBody from "hooks/useAddDataAttributeToBody";

import Header from "components/Header";

import Navigation from "ui/Navigation";
import NavigationAction from "ui/NavigationAction";
import { homeLinks } from "router/links";

import { showDrawer } from "store/ducks/ui/drawer";

import { CONTACT_FORM } from "constants/drawer";
import { NAVIGATION_MAP } from "./helpers";

const BaseTemplate = ({ children }) => {
  const dispatch = useDispatch();

  const { getUser } = useAuth();
  const { name, email } = getUser();

  useAddDataAttributeToBody("data-base-template");

  const handleQuestionClick = () => {
    dispatch(
      showDrawer({
        content: CONTACT_FORM,
        headerProps: {
          hideBorder: true,
        },
        drawerProps: {
          PaperProps: {
            className: "min-h-[37%]",
          },
        },
      })
    );
  };

  return (
    <div className="min-h-dvh flex flex-col justify-between pb-[var(--footer-height)]">
      {/*label HEADER*/}
      <Header
        name={name}
        email={email}
        className="sticky top-0 bg-base-0 z-10"
        onActionClick={handleQuestionClick}
      />
      {/*label CONTENT*/}
      <div className="flex flex-1 flex-col container mx-auto">{children}</div>

      {/*label NAVIGATION*/}
      {/*  todo move to component*/}
      <div
        className={
          "fixed left-0 right-0 bottom-0 bg-base-20 border-t-[1px] border-base-1000/.1 z-10"
        }
      >
        <div className="container mx-auto py-1 px-4 pb-sa-bottom-0">
          <Navigation showLabels classes={{ root: "bg-inherit" }}>
            {homeLinks.map(({ name, label, path }) => (
              <NavigationAction
                key={name}
                label={label}
                icon={NAVIGATION_MAP[name]?.icon}
                component={NAVIGATION_MAP[name].component || NavLink}
                to={path}
              />
            ))}
          </Navigation>
        </div>
      </div>
    </div>
  );
};

export default BaseTemplate;
