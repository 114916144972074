import { useEffect } from "react";

/**
 * Custom hook to add a specific data attribute to the body element.
 * @param {string} attributeName - The name of the data attribute to add (e.g., 'data-main').
 * @param {string} [value] - Optional value to set for the data attribute.
 * @param {boolean} [isActive=true] - Whether the attribute should be added (defaults to true).
 */
const useAddDataAttributeToBody = (
  attributeName,
  value = "",
  isActive = true
) => {
  useEffect(() => {
    if (!isActive || !attributeName) return;

    // Add the data attribute to the body
    if (value) {
      document.body.setAttribute(attributeName, value);
    } else {
      document.body.setAttribute(attributeName, "");
    }

    // Cleanup: Remove the data attribute when the component unmounts
    return () => {
      document.body.removeAttribute(attributeName);
    };
  }, [attributeName, value, isActive]); // Dependencies ensure reactivity
};

export default useAddDataAttributeToBody;
