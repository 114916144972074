import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";

import { showDrawer } from "store/ducks/ui/drawer";

import { useGetTemplateQuery } from "services/templates";
import { useCreateAdMutation, useApproveAdMutation } from "services/ads";

import { getComponent } from "utils/components";
import { templateDataAdapter } from "utils/templates";
import { formatDate } from "utils/date";

import { TEMPLATE_ID } from "constants/searchParams";
import { ADD_TO_PLAYLIST } from "constants/drawer";
import { MONO_PRODUCT_FIELD, MULTI_PRODUCT_FIELD } from "constants/ads";

import ProductsForm from "pages/AdsCreate/pages/ProductsForm";
import SelectProduct from "pages/SelectProduct";
import FooterActions from "pages/AdsCreate/components/FooterActions";

import IconButton from "ui/IconButton";

import pages from "router/links";

import VideoPlayer from "../PlaylistCreate/pages/VideoPlayer";
import Loader from "components/Loader";

import { ENTITIES } from "components/Loader/helpers";
import { generateSchema, STEPS } from "./helpers";

const STEPS_MAP = {
  default: ProductsForm,
  [STEPS.PRODUCTS_FORM]: ProductsForm,
  [STEPS.PRODUCTS_CATALOG]: SelectProduct,
  [STEPS.VIDEO_PLAYER]: VideoPlayer,
  [STEPS.LOADER]: Loader,
};

const currentDate = formatDate();

const AdsCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [step, setStep] = useState(STEPS.PRODUCTS_FORM);
  const [isLoadingSchema, setIsLoadingSchema] = useState(true);
  const [originalSchema, setOriginalSchema] = useState({});
  const [yupSchema, setYupSchema] = useState();
  const [currentProduct, setCurrentProduct] = useState(null);
  const [isProducts, setIsProducts] = useState(false);
  const [searchParams] = useSearchParams();

  const Component = getComponent(STEPS_MAP, step);
  const templateId = searchParams.get(TEMPLATE_ID);
  const { data: templateData = {}, isLoading } =
    useGetTemplateQuery(templateId);

  // todo GLOBAL CODE REFACTORING
  // label CREATE AD
  const [
    createAd,
    {
      isLoading: isLoadingCreateAd,
      isSuccess: isSuccessCreateAd,
      isError: isErrorCreateAd,
      data: createData,
    },
  ] = useCreateAdMutation();

  // label APPROVE PLAYLIST
  const [approveAd] = useApproveAdMutation();

  const {
    register,
    trigger,
    handleSubmit,
    getValues,
    setValue,
    resetField,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(yupSchema) });

  useEffect(() => {
    if (isLoading && !yupSchema) {
      setIsLoadingSchema(true);
    }

    if (!isLoading && !!yupSchema) {
      setIsLoadingSchema(false);
    }
  }, [isLoading, yupSchema]);

  useEffect(() => {
    if (templateData && !isLoading) {
      const { schema, yupSchema, isProducts } = generateSchema(
        // label replace to mock config for Development
        templateData?.config
      );
      setIsProducts(isProducts);
      setOriginalSchema(schema);
      setYupSchema(yupSchema);
    }
  }, [isLoading, templateData]);

  // label CREATE AD
  useEffect(() => {
    if (isLoadingCreateAd) {
      setStep(STEPS.LOADER);
    }
  }, [isLoadingCreateAd]);

  useEffect(() => {
    if (isSuccessCreateAd) {
      setStep(STEPS.VIDEO_PLAYER);
    }
  }, [isSuccessCreateAd]);

  useEffect(() => {
    if (isErrorCreateAd) {
      setStep(STEPS.PRODUCTS_FORM);
    }
  }, [isErrorCreateAd]);

  const VideoPlayerActions = (props) => (
    <div className="sticky bottom-6 flex gap-2">
      <FooterActions {...props} />
    </div>
  );

  // label COMPONENT PROPS
  const getComponentProps = useCallback(
    (step) => {
      let props = {};
      switch (step) {
        default:
        case STEPS.PRODUCTS_FORM:
          props = {
            headerProps: {
              title: `${templateData?.name} ${currentDate}`,
              onBack: () => {
                navigate(pages.templates.path);
              },
            },
            isLoading: isLoadingCreateAd,
            isLoadingSchema,
            preview: templateData?.preview_url,
            schema: originalSchema,
          };
          break;
        case STEPS.PRODUCTS_CATALOG:
          props = {
            headerProps: {
              title: "Select a Product",
              onBack: () => setStep(STEPS.PRODUCTS_FORM),
              deleteParams: ["search", "view"],
              ActionSideRight: (
                <IconButton
                  aria-label="add new product"
                  variant="contained"
                  size="medium"
                  onClick={() => navigate(pages.addNewProduct.path)}
                >
                  <PlusIcon />
                </IconButton>
              ),
            },
            deleteParams: ["search", "view"],
          };
          break;
        case STEPS.VIDEO_PLAYER:
          props = {
            headerProps: {
              title: "New Ad",
              onBack: () => setStep(STEPS.PRODUCTS_FORM),
            },
            videoUrl: createData?.video_url,
            poster: createData?.min_preview_url,
            actionsComponent: VideoPlayerActions,
            actionsComponentProps: {
              onAddToPlaylist: () => {
                dispatch(
                  showDrawer({
                    content: ADD_TO_PLAYLIST,
                    headerProps: {
                      title: "Move to Playlist",
                    },
                    drawerProps: {
                      PaperProps: {
                        className: "min-h-[450px]",
                      },
                    },
                    data: {
                      selectedAds: [createData.id],
                    },
                  })
                );
              },
              onSave: () => {
                approveAd(createData.id)
                  .unwrap()
                  .then(() => {
                    navigate(pages.ads.path);
                  });
              },
            },
          };
          break;
        case STEPS.LOADER:
          props = {
            entity: ENTITIES.AD,
          };
          break;
      }

      return props;
    },
    [
      templateData?.name,
      templateData?.preview_url,
      isLoadingCreateAd,
      isLoadingSchema,
      originalSchema,
      createData?.video_url,
      createData?.min_preview_url,
      createData?.id,
      navigate,
      dispatch,
      approveAd,
    ]
  );

  const handleSelectProduct = (e, product) => {
    if (isProducts) {
      setValue(
        `${MULTI_PRODUCT_FIELD}.${currentProduct}.${MONO_PRODUCT_FIELD}`,
        product
      );
    } else {
      setValue(MONO_PRODUCT_FIELD, product);
    }
    setStep(STEPS.PRODUCTS_FORM);
  };

  const onSubmit = (data) => {
    createAd({
      templateId,
      data: templateDataAdapter(data),
    });
  };

  return (
    <form onSubmit={handleSubmit((data, e) => onSubmit(data, e))}>
      {/*todo*/}
      <Component
        isLoading={isLoading || !templateData}
        register={register}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        resetField={resetField}
        trigger={trigger}
        watch={watch}
        template={templateData}
        setCurrentProduct={setCurrentProduct}
        setStep={setStep}
        onChangeStep={setStep}
        onSelect={handleSelectProduct}
        {...getComponentProps(step)}
      />
    </form>
  );
};

export default AdsCreate;
