import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import useSearch from "hooks/useSearch";
import useDebounce from "hooks/useDebounce";

import {
  useGetTemplatesByCategoryQuery,
  useGetTemplatesRecentlyQuery,
} from "services/templates";
import { hideDialog, showDialog } from "store/ducks/ui/dialog";

import CommonTemplate from "templates/CommonTemplate";

import Button from "ui/Button";

import SearchInput from "components/SearchInput";
import MediaCard from "components/MediaCard";
import SkeletonWrapper from "components/SkeletonWrapper";
import ComponentFallbackRenderer from "components/ComponentFallbackRenderer";

import { CATEGORIES } from "constants/categories";
import { TEMPLATE_CONTENT } from "constants/dialog";
import { TEMPLATE_ID } from "constants/searchParams";

import pages from "router/links";
import { useState } from "react";

const PAGINATION = {
  limit: 10,
  page: 1,
};

const TemplatesList = () => {
  const dispatch = useDispatch();

  const { templatesId } = useParams();

  const [page, setPage] = useState(PAGINATION.page);

  const [search, setSearch] = useSearch();
  const debouncedSearch = useDebounce(700);

  const useQuery =
    templatesId === CATEGORIES.recently.id
      ? useGetTemplatesRecentlyQuery
      : useGetTemplatesByCategoryQuery;

  const {
    data: { data: templateData = [], total } = {},
    isLoading: isLoadingTemplates,
    isFetching: isFetchingTemplates,
  } = useQuery({
    categories: [templatesId],
    page,
    limit: PAGINATION.limit,
    search,
  });

  const handleClickOpen = (e, { id, name, productsCount, img }) => {
    dispatch(
      showDialog({
        content: TEMPLATE_CONTENT,
        props: {
          id,
          img,
          description: name,
          productsCount,
          buttonProps: {
            component: "NavLink",
            to: `${pages.adCreate.path}?${TEMPLATE_ID}=${id}`,
          },
          onClick: () => dispatch(hideDialog()),
          onClose: () => dispatch(hideDialog()),
        },
      })
    );
  };

  const handleMoreData = () => {
    setPage((page) => page + 1);
  };

  return (
    <CommonTemplate
      headerProps={{
        title: CATEGORIES[templatesId]?.label,
      }}
    >
      <div className="flex flex-col gap-4">
        {/*label SEARCH*/}

        <SearchInput.Fixed
          fullWidth
          placeholder="Search Templates"
          defaultValue={search}
          onChange={({ target }) => {
            debouncedSearch(() => {
              setPage(1);
              setSearch(target.value);
            });
          }}
        />
        <InfiniteScroll
          next={handleMoreData}
          dataLength={templateData.length}
          hasMore={page * PAGINATION.limit < total}
          scrollThreshold={0.55}
          loader={null}
          className="flex flex-1 flex-col"
        >
          <ComponentFallbackRenderer
            data={templateData}
            isLoading={isFetchingTemplates}
          >
            {(templates) => (
              <div className="grid grid-cols-3 gap-x-2 gap-y-4 px-4 pb-6">
                {(isLoadingTemplates
                  ? Array.from({ length: 9 }, () => ({ uuid: uuidv4() }))
                  : templates
                ).map(
                  ({ uuid, id = uuid, name, products_count, preview_url }) => (
                    <SkeletonWrapper
                      key={id}
                      condition={isLoadingTemplates}
                      Component={MediaCard.Skeleton}
                    >
                      <div className="flex flex-col relative">
                        <MediaCard
                          key={id}
                          title={name}
                          img={preview_url}
                          aspectRatio="auto"
                          Description={
                            <span className="text-base-500 text-sm">
                              Products: {products_count || 1}
                            </span>
                          }
                          imageClassName="aspect-[9/16]"
                          component={Button}
                          componentProps={{
                            onClick: (e) => {
                              handleClickOpen(e, {
                                id,
                                name,
                                productsCount: products_count,
                                img: preview_url,
                              });
                            },
                          }}
                        />
                      </div>
                    </SkeletonWrapper>
                  )
                )}
              </div>
            )}
          </ComponentFallbackRenderer>
        </InfiniteScroll>
      </div>
    </CommonTemplate>
  );
};

export default TemplatesList;
