import { DotLottieReact } from "@lottiefiles/dotlottie-react";

import animation from "assets/animations/bottles-spinner.lottie";

import { getUseRequest } from "./helpers";

const Loader = ({ entity }) => {
  const useRequest = getUseRequest(entity);
  const { data: progress = 0 } = useRequest(null, {
    pollingInterval: 3000,
    refetchOnReconnect: true,
    refetchOnFocus: true,
  });

  return (
    <div className="min-h-svh flex flex-col gap-20 flex-1 items-center justify-center p-11 relative">
      <div className="flex flex-col gap-2.5 items-center text-center">
        <span className="text-2xl font-medium">
          There's magic going on here!{" "}
        </span>
        <span className="text-md text-base-500">Please wait a moment.</span>
      </div>

      <div className="relative">
        <DotLottieReact
          src={animation}
          direction="1"
          playMode="normal"
          loop
          autoplay
        />
        <span className="absolute inset-0 flex items-center justify-center text-[clamp(16px,3vw,20px)]">
          {Math.floor(progress)}%
        </span>
      </div>
      <div className="text-md text-base-500">Loading...</div>
    </div>
  );
};

export default Loader;
