import { createContext, useState } from "react";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";

import getIdentity from "utils/auth";

import pages from "router/links";
import { COOKIE_TOKEN_NAME, COOKIE_USER } from "constants/index";

export const AuthContext = createContext({
  login: () => {},
  logout: () => {},
  getUser: () => {},
  isLogged: false,
});

const AuthProvider = ({ children, apiUrl }) => {
  const [isLogged, setIsLogged] = useState(!!Cookies.get(COOKIE_TOKEN_NAME));
  Sentry.setUser(getUser());

  const login = ({ email, password }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const userData = {
          email,
          password,
        };

        const response = await fetch(`${apiUrl}/auth/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        });

        const responseData = await response.json();

        if (response.ok) {
          const { access_token } = responseData;
          if (access_token) {
            Cookies.set(COOKIE_TOKEN_NAME, access_token);
            Cookies.set(COOKIE_USER, JSON.stringify(getIdentity(access_token)));
            setIsLogged(true);

            resolve({
              success: true,
              redirectTo: pages.playlists.path,
            });
          } else {
            resolve({
              success: false,
              error: {
                message: "Login failed",
                name: "Invalid email or password",
              },
            });
          }
        } else {
          reject(responseData);
        }
      } catch (error) {
        reject({
          message: "An error occurred during login",
          name: error.name,
        });
      }
    });
  };

  const logout = () => {
    return new Promise(async (resolve) => {
      await Cookies.remove(COOKIE_TOKEN_NAME);
      await Cookies.remove(COOKIE_USER);

      await setIsLogged(false);

      const result = {
        success: true,
        redirectTo: pages.login.path,
      };

      resolve(result);
    });
  };

  function getUser() {
    return JSON.parse(Cookies.get(COOKIE_USER) ?? "{}");
  }

  const authValues = {
    login,
    logout,
    getUser,
    isLogged,
  };

  return (
    <AuthContext.Provider value={authValues}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
