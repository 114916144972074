import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { SEARCH } from "constants/searchParams";

const useSearch = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getInitialSearchTerm = () => {
    const params = new URLSearchParams(location.search);
    return params.get(SEARCH) || "";
  };

  const [searchTerm, setSearchTerm] = useState(getInitialSearchTerm);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (searchTerm) {
      params.set(SEARCH, searchTerm);
    } else {
      params.delete(SEARCH);
    }
    navigate({ search: params.toString() }, { replace: true });
  }, [searchTerm, navigate, location.search]);

  return [searchTerm || undefined, setSearchTerm];
};

export default useSearch;
