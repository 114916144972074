import { renderComponent } from "utils/components";
import CommonTemplate from "templates/CommonTemplate";

import Button from "ui/Button";

const Actions = ({ isLoading, submitButtonLabel, onSubmit }) => (
  <Button.Loading
    variant="contained"
    fullWidth
    loading={isLoading}
    classes={{ root: "sticky bottom-6" }}
    onClick={onSubmit}
  >
    {submitButtonLabel}
  </Button.Loading>
);

const VideoPlayer = ({
  headerProps,
  videoUrl,
  poster,
  isLoading,
  submitButtonLabel = "Save",
  actionsComponent = Actions,
  actionsComponentProps = {},
  onSubmit = () => {},
}) => {
  return (
    <CommonTemplate headerProps={headerProps}>
      <div className="flex flex-1 flex-col justify-between gap-2">
        <div className="flex flex-1 flex-col items-center gap-12">
          <video
            src={videoUrl}
            autoPlay
            muted
            controls
            preload="auto"
            controlsList="nodownload nofullscreen noremoteplayback"
            className="w-full aspect-[9/16] object-cover rounded-lg max-w-fit max-h-[calc(100dvh-24px*2-64px-66px-0.5rem)]" //heights: content padding * 2(top, bottom), header, action button, gap
            poster={poster}
          />
        </div>
        {renderComponent(actionsComponent, {
          isLoading,
          submitButtonLabel,
          onSubmit,
          ...actionsComponentProps,
        })}
      </div>
    </CommonTemplate>
  );
};

export default VideoPlayer;
