import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useAuth from "hooks/useAuth";

import { yupResolver } from "@hookform/resolvers/yup";

import CommonTemplate from "templates/CommonTemplate";

import Button from "ui/Button";
import IconButton from "ui/IconButton";
import Input from "ui/Input";
import InputAdornment from "ui/InputAdornment";

import { ReactComponent as EyeIcon } from "assets/icons/eyes.svg";
import { ReactComponent as NoEyeIcon } from "assets/icons/no-eyes.svg";

import { schema } from "./schema";

const Login = () => {
  const navigate = useNavigate();

  const { login, isLogged } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (isLogged) {
      navigate("/playlists");
    }
  }, [navigate, isLogged]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = ({ email, password }) => {
    login({
      email,
      password,
    }).catch(({ message, ...error }) => {
      if (error) {
        setError("password", {
          type: "manual",
          message: message,
        });
      }
    });
  };

  return (
    <CommonTemplate
      headerProps={{
        title: "Login",
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-1 flex-col justify-between px-4 py-3"
      >
        <div className="flex flex-col gap-4">
          <Input
            {...register("email")}
            autoComplete="off"
            label="Email"
            type="email"
            helperText={errors["email"]?.message}
            error={!!errors["email"]}
          />
          <Input
            {...register("password")}
            autoComplete="off"
            label="Password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className="text-base-500 mr-4">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <EyeIcon className="w-[20px]" />
                    ) : (
                      <NoEyeIcon className="w-[20px]" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText={errors["password"]?.message}
            error={!!errors["password"]}
          />
        </div>
        <Button variant="contained" type="submit">
          Login
        </Button>
      </form>
    </CommonTemplate>
  );
};

export default Login;
