import Input from "ui/Input";
import InputAdornment from "ui/InputAdornment";

import InputLabel from "components/ProductCard/components/InputLabel";

const Footer = ({ priceInputProps, PriceIcon }) => {
  return (
    <div className="flex gap-4">
      <Input
        {...priceInputProps}
        fullWidth
        variant="standard"
        autoComplete="off"
        type="text"
        label={<InputLabel Icon={PriceIcon}>Price</InputLabel>}
        placeholder="100"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          classes: { root: "mt-[6px]" },
        }}
        inputProps={{
          inputMode: "decimal",
        }}
      />
    </div>
  );
};

export default Footer;
