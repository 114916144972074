import cx from "classnames";

import Title from "components/Title";
import Button from "ui/Button";
import { renderComponent } from "utils/components";

const Section = ({
  title,
  titleSection,
  titleProps,
  actionsSection = null,
  onButtonClick,
  buttonLabel,
  buttonProps,
  className,
  children,
}) => {
  return (
    <div className={cx("flex flex-1 flex-col gap-4", className)}>
      {/*label header*/}
      <div className="flex justify-between items-end">
        <div className="flex items-end">
          {titleSection ? (
            renderComponent(titleSection(title, titleProps))
          ) : (
            <Title size="2xl" {...titleProps}>
              {title}
            </Title>
          )}
        </div>
        <div className="flex items-end">
          {actionsSection
            ? renderComponent(actionsSection)
            : (onButtonClick || buttonProps) &&
              buttonLabel && (
                <Button
                  variant="text"
                  size="small"
                  onClick={onButtonClick}
                  {...buttonProps}
                >
                  {buttonLabel}
                </Button>
              )}
        </div>
      </div>
      {/*label content*/}
      {children && (
        <div className="content flex flex-col flex-1">{children}</div>
      )}
    </div>
  );
};

export default Section;
