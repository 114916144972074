import { useGetPlaylistProgressQuery } from "services/playlists";
import { useGetAdProgressQuery } from "services/ads";

export const ENTITIES = {
  PLAYLIST: "PLAYLIST",
  AD: "AD",
};

export const getUseRequest = (entity) => {
  switch (entity) {
    default:
    case ENTITIES.PLAYLIST:
      return useGetPlaylistProgressQuery;
    case ENTITIES.AD:
      return useGetAdProgressQuery;
  }
};
