import { api } from "services/api";

export const storesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStores: build.query({
      query: ({ limit, playlistId } = {}) => ({
        url: "stores",
        params: { limit, playlist: playlistId },
      }),
      providesTags: [{ type: "Stores", id: "LIST" }],
    }),
    getStore: build.query({
      query: (id) => `stores/${id}`,
    }),
  }),
});

export const { useGetStoresQuery, useGetStoreQuery } = storesApi;
