import { useCallback, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { v4 as uuidv4 } from "uuid";

import useSearch from "hooks/useSearch";
import useDebounce from "hooks/useDebounce";
import useCheckDataAttribute from "hooks/useCheckDataAttribute";

import { hideDrawer, showDrawer } from "store/ducks/ui/drawer";

import { useGetAdsQuery, useDeleteAdsMutation } from "services/ads";

import Button from "ui/Button";
import Skeleton from "ui/Skeleton";

import SearchInput from "components/SearchInput";
import Section from "components/Section";
import Title from "components/Title";
import MediaCard from "components/MediaCard";
import NumberedCheckbox from "components/NumberedCheckbox";
import ComponentFallbackRenderer from "components/ComponentFallbackRenderer";
import SkeletonWrapper from "components/SkeletonWrapper";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "assets/icons/more-with-border.svg";

import { VIEWS, MODE } from "constants/index";
import { PLAYLIST_TYPES } from "constants/playlists";
import { AD_ACTIONS, AD_UPLOAD_FILE, ADD_TO_PLAYLIST } from "constants/drawer";

import Actions from "pages/Ads/components/Actions";

import EmptyData from "components/EmptyData";

import { ReactComponent as PlaylistIcon } from "assets/icons/playlist.svg";
import { ReactComponent as RenameIcon } from "assets/icons/rename.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

import { formatMilliseconds } from "utils/date";
import { replaceParams } from "utils/router";

import pages from "router/links";

const PAGINATION = {
  limit: 10,
  page: 1,
};

const AdsList = ({
  onSelectChange = () => {},
  onSelectAdChange,
  buttonProps,
  cardWithPlayer = true,
  showCreateAdButton = false,
  EmptyStateComponent = EmptyData,
  onSelect = () => {},
  onSubmitFile,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(PAGINATION.page);

  const [search, setSearch] = useSearch("");
  const debouncedSearch = useDebounce(700);

  const isBaseTemplate = useCheckDataAttribute(
    document.body,
    "data-base-template"
  );

  const {
    data: { data: adsData = [], total } = {},
    isLoading: isAdsLoading,
    isFetching: isAdsFetching,
  } = useGetAdsQuery({
    page,
    limit: PAGINATION.limit,
    search,
  });
  const [deleteAds] = useDeleteAdsMutation();

  const [selectedAds, setSelectedAds] = useState([]);

  const [view, setView] = useState(VIEWS.CARD);
  const [mode, setMode] = useState(MODE.VIEW);

  const isViewMode = mode === MODE.VIEW;
  const isEditMode = mode === MODE.EDIT;

  const isCardView = view === VIEWS.CARD;
  const isListView = view === VIEWS.LIST;

  const countOfAds = total ?? adsData.length;
  const countOfSelectedAds = selectedAds.length;

  // todo optimize
  // record IDs of all selected Ads
  useEffect(() => {
    onSelectChange(selectedAds);
  }, [onSelectChange, selectedAds]);

  // record full objects of all selected Ads
  useEffect(() => {
    if (onSelectAdChange) {
      const filteredData = selectedAds
        .map((id) => adsData.find((item) => item.id === id))
        .filter((item) => item !== undefined);
      onSelectAdChange(filteredData);
    }
  }, [adsData, onSelectAdChange, selectedAds]);

  const handleProductClick = (adId) => {
    if (selectedAds.includes(adId)) {
      setSelectedAds(selectedAds.filter((id) => id !== adId));
    } else {
      setSelectedAds((prevAds) => [...prevAds, adId]);
    }
  };

  const handleSwitchMode = () => {
    if (isViewMode) {
      setMode(MODE.EDIT);
    }
    if (isEditMode) {
      setSelectedAds([]);
      setMode(MODE.VIEW);
    }
  };

  const menuItems = useMemo(
    () => [
      {
        icon: PlaylistIcon,
        label: "Move to Playlist",
        onClick: (e, { id }) => {
          toggleDrawer(id);
        },
      },
      {
        icon: RenameIcon,
        label: "Rename",
        onClick: (e, { id }) => {
          navigate(
            replaceParams(pages.adEdit.path, {
              adId: id,
            })
          );
          dispatch(hideDrawer());
        },
      },
      {
        icon: DeleteIcon,
        label: "Delete",
        color: "text-error",
        onClick: (e, { id, ids }) => {
          deleteAds(ids)
            .unwrap()
            .then(() => {
              if (isEditMode) {
                handleSwitchMode();
              }
            });
          dispatch(hideDrawer());
        },
      },
    ],
    // the dependency should be exactly toggleDrawerFunction
    [deleteAds, dispatch, navigate, toggleDrawerFunction]
  );

  const handleMoreData = () => {
    setPage((page) => page + 1);
  };

  function toggleDrawerFunction(id) {
    dispatch(
      showDrawer({
        content: ADD_TO_PLAYLIST,
        headerProps: {
          title: "Move to Playlist",
        },
        drawerProps: {
          PaperProps: {
            className: "min-h-[450px]",
          },
        },
        data: { selectedAds: selectedAds.length ? selectedAds : [id] },
      })
    );
  }

  const toggleDrawer = useCallback(toggleDrawerFunction, [
    dispatch,
    selectedAds,
  ]);

  const handleOpenDrawer = (e, { id, name, minPreviewUrl, duration }) => {
    dispatch(
      showDrawer({
        content: AD_ACTIONS,
        base: true,
        data: {
          menuItems,
          ad: {
            id,
            ids: selectedAds.length ? selectedAds : [id],
            name,
            img: minPreviewUrl,
            duration,
          },
        },
      })
    );
  };

  const handleUploadFile = useCallback(() => {
    dispatch(
      showDrawer({
        content: AD_UPLOAD_FILE,
        headerProps: {
          title: "Create a new Ad",
        },
        data: { onSubmit: onSubmitFile },
      })
    );
  }, [dispatch, onSubmitFile]);

  const ActionsMenu = useCallback(
    () => (
      <div className="flex p-2 gap-2 bg-white bg-opacity-80 backdrop-blur-lg border border-base-100 rounded-2xl">
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={handleUploadFile}
        >
          <span className="text-base-1000">Upload File</span>
        </Button>
        <Button
          fullWidth
          variant="contained"
          disabled={!countOfAds}
          onClick={() =>
            navigate({
              pathname: replaceParams(pages.playlistCreate.path, {
                type: PLAYLIST_TYPES.NEW,
              }),
            })
          }
        >
          Create new Playlist
        </Button>
      </div>
    ),
    [countOfAds, handleUploadFile, navigate]
  );

  const EmptyState = useMemo(() => {
    if (!search) {
      return <EmptyStateComponent />;
    }

    return (
      <div className="flex flex-1 flex-col gap-4 pb-fixed-btn-space-4">
        <SearchInput.Fixed
          fullWidth
          placeholder="Search Ad"
          defaultValue={search}
          onChange={({ target }) => {
            debouncedSearch(() => {
              setPage(1);
              setSearch(target.value);
            });
          }}
        />
        <EmptyData />
      </div>
    );
  }, [debouncedSearch, search, setSearch]);

  return (
    <ComponentFallbackRenderer
      data={adsData}
      alt={EmptyState}
      isLoading={isAdsFetching}
    >
      {(ads) => (
        // label ADS LIST
        <div className="flex flex-1 flex-col gap-4 pb-fixed-btn-space-4">
          {/*label SEARCH*/}
          <SearchInput.Fixed
            fullWidth
            placeholder="Search Ad"
            defaultValue={search}
            onChange={({ target }) => {
              debouncedSearch(() => {
                setPage(1);
                setSearch(target.value);
              });
            }}
          />
          <Section
            title="All Ads"
            titleSection={(title) => (
              <Title size="2xl">
                {isAdsFetching ? (
                  <Skeleton width={130} />
                ) : (
                  <>
                    {title}{" "}
                    <span className="text-base-500">{`(${countOfAds})`}</span>
                  </>
                )}
              </Title>
            )}
            actionsSection={
              <Actions
                onToggle={setView}
                view={view}
                isEditMode={isEditMode}
                isShowSelect={!!countOfAds}
                countOfSelected={countOfSelectedAds}
                onSelect={handleSwitchMode}
              />
            }
            className="px-4 py-3"
          >
            <InfiniteScroll
              next={handleMoreData}
              dataLength={ads.length}
              hasMore={page * PAGINATION.limit < total}
              scrollThreshold={0.55}
              loader={null}
            >
              <div
                className={cx({
                  "grid grid-cols-2 gap-x-2 gap-y-4 m-[1px]": isCardView,
                  "flex flex-1 flex-col gap-2 m-[1px]": isListView,
                })}
              >
                {(isAdsLoading
                  ? Array.from({ length: 6 }, () => ({ uuid: uuidv4() }))
                  : ads
                ).map(
                  ({
                    uuid,
                    id = uuid,
                    minPreviewUrl,
                    previewUrl,
                    videoUrl,
                    name,
                    date,
                    duration,
                  }) => {
                    const indexOf = selectedAds.indexOf(id);
                    const isChecked = indexOf >= 0;
                    const orderNumber = isChecked ? indexOf + 1 : null;
                    const formattedDuration = formatMilliseconds(duration);

                    const CardComponent = isCardView
                      ? MediaCard
                      : MediaCard.Secondary;

                    return (
                      <SkeletonWrapper
                        key={id}
                        condition={isAdsLoading}
                        Component={AdsList.CardSkeleton}
                      >
                        <div
                          className={cx({
                            "flex flex-col relative": isCardView,
                            "flex gap-4": isListView,
                          })}
                        >
                          {isEditMode && (
                            <NumberedCheckbox
                              checked={isChecked}
                              orderNumber={orderNumber}
                              onClick={() => handleProductClick(id)}
                              color={isCardView ? "secondary" : "primary"}
                              className={cx({
                                "z-[1] absolute": isCardView,
                              })}
                            />
                          )}
                          {/*todo code refactoring*/}
                          <CardComponent
                            img={minPreviewUrl || previewUrl}
                            title={name}
                            date={date}
                            duration={formattedDuration}
                            isActive={isChecked}
                            aspectRatio="auto"
                            imageClassName="aspect-[9/16]"
                            className={cx("items-start", {
                              "opacity-40 pointer-events-none":
                                isEditMode && !isChecked,
                            })}
                            imgComponentProps={{
                              className: "aspect-[9/16]",
                            }}
                            playerProps={{
                              withPlayer: cardWithPlayer,
                              url: videoUrl,
                            }}
                            actionComponentProps={{
                              onClick: (e) =>
                                handleOpenDrawer(e, {
                                  id,
                                  minPreviewUrl,
                                  previewUrl,
                                  videoUrl,
                                  name,
                                  date,
                                  duration,
                                }),
                            }}
                            onClickButtonMore={(e) =>
                              handleOpenDrawer(e, {
                                id,
                                minPreviewUrl,
                                previewUrl,
                                videoUrl,
                                name,
                                date,
                                duration,
                              })
                            }
                            actionButtonIcon={MoreIcon}
                            showMoreBtn
                            component={Button}
                            componentProps={{
                              onClick: (e) => {
                                onSelect(e, {
                                  id,
                                  minPreviewUrl,
                                  previewUrl,
                                  videoUrl,
                                  name,
                                  date,
                                  duration,
                                });
                              },
                              onLongPress: () => {
                                if (mode !== MODE.EDIT) {
                                  setMode(MODE.EDIT);
                                  handleProductClick(id);
                                }
                              },
                            }}
                          />
                        </div>
                      </SkeletonWrapper>
                    );
                  }
                )}
              </div>
            </InfiniteScroll>
          </Section>

          {/*todo*/}
          {/*label Add to Playlist BUTTON*/}
          {!isAdsFetching && (
            <div
              className={cx("fixed container px-4 z-10", {
                "bottom-fixed-btn-4": isBaseTemplate,
                "bottom-safe-bottom-4": !isBaseTemplate,
              })}
            >
              {!selectedAds.length && !isEditMode && showCreateAdButton && (
                <ActionsMenu />
              )}
              {!!selectedAds.length && (
                <Button
                  fullWidth
                  variant="contained"
                  startIcon={<PlusIcon className="w-4" />}
                  onClick={() => toggleDrawer()}
                  {...buttonProps}
                >
                  {buttonProps?.label ?? "Add to Playlist"}
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </ComponentFallbackRenderer>
  );
};

AdsList.CardSkeleton = () => (
  <div className="flex flex-col gap-3 ">
    <Skeleton.Round
      sx={{
        aspectRatio: "9 / 16",
        width: "100%",
        height: "auto",
      }}
    />
    <Skeleton width="60%" sx={{ fontSize: "1rem" }} />
  </div>
);

export default AdsList;
