import { useCallback, useEffect, useState, useMemo } from "react";
import { useFieldArray } from "react-hook-form";
import { useDispatch } from "react-redux";

import { hideDrawer, showDrawer } from "store/ducks/ui/drawer";
import { formatMilliseconds } from "utils/date";

import CommonTemplate from "templates/CommonTemplate";

import Button from "ui/Button";

import Section from "components/Section";
import MediaCard from "components/MediaCard";
import ComponentFallbackRenderer from "components/ComponentFallbackRenderer";
import Title from "components/Title";

import { AD_ACTIONS } from "constants/drawer";
import { COUNT_VIDEO_LIMIT } from "pages/PlaylistCreate/New/helpers";

import { ReactComponent as UpIcon } from "assets/icons/arrow-up.svg";
import { ReactComponent as DownIcon } from "assets/icons/arrow-down.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

const ListSelectedAds = ({ headerProps, control, onSubmit = () => {} }) => {
  const dispatch = useDispatch();

  const {
    fields = [],
    remove,
    move,
  } = useFieldArray({
    control,
    name: "ads",
  });
  const [totalSlots, setTotalSlots] = useState(0);
  const countSelectedAds = fields.length;
  const isDisableButton = useMemo(
    () => totalSlots > COUNT_VIDEO_LIMIT,
    [totalSlots]
  );

  useEffect(() => {
    setTotalSlots(fields.reduce((total, { slots = 0 }) => total + slots, 0));
  }, [fields]);

  const moveItem = useCallback(
    (id, direction = "up") => {
      const index = fields.findIndex((field) => field.id === id);
      const newIndex = direction === "down" ? index + 1 : index - 1;

      if (index !== -1 && newIndex >= 0 && newIndex < fields.length) {
        move(index, newIndex);
      }
    },
    [fields, move]
  );

  const deleteItem = useCallback(
    (id) => {
      const index = fields.findIndex((field) => field.id === id);
      if (index !== -1) {
        remove(index);
      }
    },
    [fields, remove]
  );

  const menuItems = useCallback(
    (id) => [
      {
        icon: UpIcon,
        label: "Move up",
        disabled: fields.length > 0 && fields[0].id === id,
        onClick: (e, { id }) => {
          moveItem(id);
          dispatch(hideDrawer());
        },
      },
      {
        icon: DownIcon,
        label: "Move down",
        disabled: fields.length > 0 && fields[fields.length - 1].id === id,
        onClick: (e, { id }) => {
          moveItem(id, "down");
          dispatch(hideDrawer());
        },
      },
      {
        icon: DeleteIcon,
        label: "Delete",
        color: "text-error",
        onClick: (e, { id }) => {
          deleteItem(id);
          dispatch(hideDrawer());
        },
      },
    ],
    [deleteItem, dispatch, fields, moveItem]
  );

  const handleOpenDrawer = (e, { id, name, minPreviewUrl, duration }) => {
    dispatch(
      showDrawer({
        content: AD_ACTIONS,
        base: true,
        data: {
          menuItems: menuItems(id),
          ad: { id, name, img: minPreviewUrl, duration },
        },
      })
    );
  };

  return (
    <CommonTemplate headerProps={headerProps}>
      <div className="flex flex-1 flex-col justify-between gap-2 px-4 py-6">
        <div className="flex flex-col gap-12">
          <Section
            title="Ads added"
            titleSection={(title) => (
              <Title size="2xl">
                <>
                  {title}{" "}
                  <span className="text-base-500">({countSelectedAds})</span>
                </>
              </Title>
            )}
          >
            <ComponentFallbackRenderer data={fields}>
              {(ads) => (
                <div className="flex flex-1 flex-col gap-2">
                  {ads.map(
                    ({ id, minPreviewUrl, previewUrl, name, duration }) => {
                      return (
                        <div key={id} className={"flex gap-4"}>
                          <MediaCard.Secondary
                            img={minPreviewUrl || previewUrl}
                            title={name}
                            duration={formatMilliseconds(duration)}
                            aspectRatio="auto"
                            imgComponentProps={{
                              className: "aspect-[9/16]",
                            }}
                            actionComponentProps={{
                              onClick: (e) =>
                                handleOpenDrawer(e, {
                                  id,
                                  minPreviewUrl,
                                  previewUrl,
                                  name,
                                  duration,
                                }),
                            }}
                            showMoreBtn
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              )}
            </ComponentFallbackRenderer>
          </Section>
        </div>
        <Button
          variant="contained"
          fullWidth
          disabled={isDisableButton}
          classes={{ root: "sticky bottom-6" }}
          onClick={onSubmit}
        >
          Create
        </Button>
      </div>
    </CommonTemplate>
  );
};

export default ListSelectedAds;
