export const STEPS = {
  FORM: "FORM",
  ADS_CATALOG: "ADS_CATALOG",
  LIST_SELECTED_ADS: "LIST_SELECTED_ADS",
  VIDEO_PLAYER: "VIDEO_PLAYER",
  ASSIGN_SCREENS: "ASSIGN_SCREENS",
  LOADER: "LOADER",
};

export const COUNT_VIDEO_LIMIT = 8;
