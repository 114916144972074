import FooterComponent from "./components/Footer";

import { renderComponent } from "utils/components";

import { ReactComponent as PriceIcon } from "assets/icons/price.svg";
import { ReactComponent as DiscountIcon } from "assets/icons/discount.svg";

const ProductCard = ({
  img,
  brand,
  name,
  variant,
  priceInputProps,
  Header,
  Footer = FooterComponent,
}) => {
  return (
    <div className="flex flex-1 gap-3 p-2 bg-base-50 rounded-2xl">
      <div className="flex justify-center items-center bg-base-0 rounded-lg w-1/4 max-h-full aspect-[92/136] box-border p-4">
        <img src={img} alt="" className="max-h-full" />
      </div>
      <div className="flex flex-1 flex-col gap-4 justify-between p-2">
        {renderComponent(Header)}
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-1">
            <span className="text-sm leading-none text-base-500 line-clamp-1">
              {brand}
            </span>
            <span className="text-base leading-none line-clamp-1">{name}</span>
          </div>
          <span className="text-sm leading-none">{variant}</span>
        </div>
        {renderComponent(Footer, {
          priceInputProps,
          PriceIcon,
          DiscountIcon,
        })}
      </div>
    </div>
  );
};

export default ProductCard;
