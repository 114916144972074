import { useCallback, useEffect, useRef, useState } from "react";
import { useCamera, useScanning, useTorch } from "react-barcode-scanner";
import { useDispatch, useSelector } from "react-redux";
import "react-barcode-scanner/polyfill";

import useBlockScroll from "hooks/useBlockScroll";

import { PRODUCT_NOT_FOUND } from "constants/drawer";

import { selectUiDrawerState, showDrawer } from "store/ducks/ui/drawer";
import { useLazyGetProductByBarcodeQuery } from "services/catalog";

import CommonTemplate from "templates/CommonTemplate";
import ProductCard from "components/ProductCard";

import Button from "ui/Button";
import IconButton from "ui/IconButton";

import { ReactComponent as BarcodeIcon } from "assets/icons/bar-code.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as FlashOnIcon } from "assets/icons/flash.svg";
import { ReactComponent as FlashOffIcon } from "assets/icons/no-flash.svg";

import styles from "./styles.module.scss";
// todo for Dev ONLY
import { barcodesMap } from "./mockData";
const isDevelopment = process.env.NODE_ENV === "development";

const Scanner = ({
  headerProps = {},
  onSelect,
  onDetect = () => {},
  changeView = () => {},
}) => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(selectUiDrawerState);

  const instance = useRef();
  const [res, setRes] = useState();
  const [isCameraSupport] = useCamera(instance);
  const [detected, open, close] = useScanning(instance, {
    formats: ["upc_a"],
  });
  const [isSupportTorch, isTorched, switchTorch] = useTorch();
  const [product, setProduct] = useState();

  const [trigger] = useLazyGetProductByBarcodeQuery();

  const { title, ...restHeaderProps } = headerProps;

  useBlockScroll();

  useEffect(() => {
    if (isCameraSupport) {
      open();

      return () => close();
    }
  }, [isCameraSupport, close, open]);

  useEffect(() => {
    if (detected) {
      const result = detected?.rawValue;

      onDetect(result);
      setRes(result);
    }
  }, [detected]);

  const showModal = useCallback(() => {
    dispatch(
      showDrawer({
        content: PRODUCT_NOT_FOUND,
        headerProps: {
          hideBorder: true,
        },
        drawerProps: {
          PaperProps: {
            className: "min-h-[30%]",
          },
        },
        data: {
          changeView,
        },
      })
    );
  }, [dispatch, changeView]);

  useEffect(() => {
    if (!!res && !product && !isOpen) {
      const valueToTrigger = isDevelopment ? barcodesMap[res] || res : res;

      trigger(valueToTrigger)
        .unwrap()
        .then((res) => {
          console.log({ res });
          if (res === null || undefined) {
            showModal();
            setRes(null);
            return;
          }

          setProduct(res);
        })
        .catch((error) => {
          setRes(null);

          console.error("Error:", error);
        });
    }
  }, [res, product, isOpen, trigger, showModal]);

  function handleDelete() {
    setRes(null);
    setProduct(null);
  }

  const handleAdd = (e) => onSelect(e, product);

  return (
    <CommonTemplate
      contentProps={{
        className: styles.content,
      }}
      headerProps={{
        ...restHeaderProps,
        className: styles.header,
        actionsColor: "text-base-0",
        ActionSideRight: isSupportTorch && (
          <div className="right-content  col-span-2 text-right">
            <IconButton
              aria-label="torch"
              className={`p-0 text-base-0`}
              onClick={switchTorch}
            >
              {isTorched ? <FlashOnIcon /> : <FlashOffIcon />}
            </IconButton>
          </div>
        ),
      }}
    >
      <div className={styles.container}>
        {product && (
          <div className="absolute flex max-h-[152px] bottom-4 left-4 right-4 z-20">
            <ProductCard
              name={product?.name}
              brand={product?.brand?.name}
              variant={product?.variant}
              img={product?.img}
              Header={() => (
                <div>
                  <IconButton
                    aria-label="info"
                    className="absolute top-2 right-2"
                    onClick={handleDelete}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
              Footer={() => (
                <div className="flex justify-end pb-2">
                  <Button variant="text" size="small" onClick={handleAdd}>
                    Add
                  </Button>
                </div>
              )}
            />
          </div>
        )}
        <video
          ref={instance}
          style={{ width: "100%", height: "100dvh", objectFit: "cover" }}
          autoPlay
          muted
          playsInline
          controls={false}
        />
        <div className={styles.overlay} />
        <div className="flex flex-col gap-4 absolute top-32 text-base-0 items-center">
          <div className="flex gap-2 text-base-0">
            <BarcodeIcon />
            <span>Scan the bar-code</span>
          </div>
          <span className="text-sm opacity-[.6]">
            Select a product to add it to the template
          </span>
        </div>
      </div>
    </CommonTemplate>
  );
};

export default Scanner;
