import cx from "classnames";
import CommonHeader from "components/CommonHeader";

import useAddDataAttributeToBody from "hooks/useAddDataAttributeToBody";

import { renderComponent } from "utils/components";

const CommonTemplate = ({
  children,
  header: Header = CommonHeader,
  headerProps = {},
  contentProps = {},
}) => {
  useAddDataAttributeToBody("data-common-template");

  const { className: contentClassName, ...restContentProps } = contentProps;

  return (
    <div className="min-h-dvh flex flex-col justify-between pb-sa-bottom-0">
      <div className="flex flex-col flex-1">
        {/*label HEADER*/}
        {renderComponent(<Header {...headerProps} />)}

        {/*label CONTENT*/}
        <div
          className={cx(
            "container flex flex-col flex-1 mx-auto",
            contentClassName
          )}
          {...restContentProps}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default CommonTemplate;
