import { useEffect } from "react";

import Input from "ui/Input";
import InputAdornment from "ui/InputAdornment";

import { createBaseProxy } from "utils/components";

import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

import "./styles.module.scss";

const SearchInput = ({ placeholder, defaultValue, fullWidth, onChange }) => {
  useEffect(() => {
    return () => {
      const url = new URL(window.location.href);
      url.searchParams.delete("search");
      window.history.replaceState({}, "", url.pathname + url.search);
    };
  }, []);

  return (
    <Input
      fullWidth={fullWidth}
      autoComplete="off"
      hiddenLabel
      placeholder={placeholder}
      defaultValue={defaultValue}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" className="text-base-500 ml-4">
            <SearchIcon className="w-[16px]" />
          </InputAdornment>
        ),
        classes: {
          root: "rounded-[1100px]",
        },
      }}
      onChange={onChange}
    />
  );
};

const FixedSearchInput = createBaseProxy(
  (props) => (
    <div id="search" className="sticky container px-4 py-2 bg-base-0 z-10">
      <SearchInput {...props} />
    </div>
  ),
  "FixedSearchInput"
);

SearchInput.Fixed = FixedSearchInput;

export default SearchInput;
