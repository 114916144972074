import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

import getIdentity from "utils/auth";

import { COOKIE_TOKEN_NAME } from "constants/index";
import { API_URL } from "constants/APIs";

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers) => {
    const token = Cookies.get(COOKIE_TOKEN_NAME);
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const api = createApi({
  reducerPath: "splitApi",
  baseQuery: baseQueryWithRetry,
  tagTypes: ["Stores"],
  endpoints: () => ({}),
  serializeQueryArgs: ({ queryArgs, endpointName }) => {
    const token = Cookies.get(COOKIE_TOKEN_NAME);
    const { id: userId, email } = getIdentity(token);

    // todo fix for infinite scroll
    const endpointsWithoutParams = [
      "getTemplatesByCategory",
      "getTemplatesRecently",
      "getAds",
      "getProductsList",
      "getPlaylists",
      "getTemplates",
    ];

    if (endpointsWithoutParams.includes(endpointName)) {
      return `${endpointName}-${userId}-${email}`;
    }

    return `${endpointName}(${JSON.stringify(queryArgs)})-${userId}-${email}`;
  },
});
