import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  useCreatePlaylistMutation,
  useApprovePlaylistMutation,
} from "services/playlists";

import { getComponent } from "utils/components";

import Form from "pages/PlaylistCreate/New/pages/Form";
import Ads from "pages/PlaylistCreate/New/pages/Ads";
import ListSelectedAds from "pages/PlaylistCreate/New/pages/ListSelectedAds";
import VideoPlayer from "pages/PlaylistCreate/pages/VideoPlayer";
import AssignScreens from "pages/AssignScreens";
import Loader from "components/Loader";

import { ENTITIES } from "components/Loader/helpers";
import { STEPS } from "./helpers";

const INPUT_FORM_NAME = "name";
const INPUT_FORM_ADS = "ads";

const STEPS_MAP = {
  default: Ads,
  [STEPS.ADS_CATALOG]: Ads,
  [STEPS.LIST_SELECTED_ADS]: ListSelectedAds,
  [STEPS.VIDEO_PLAYER]: VideoPlayer,
  [STEPS.FORM]: Form,
  [STEPS.ASSIGN_SCREENS]: AssignScreens,
  [STEPS.LOADER]: Loader,
};
const New = () => {
  const [step, setStep] = useState(STEPS.ADS_CATALOG);
  const Component = getComponent(STEPS_MAP, step);

  // label CREATE PLAYLIST
  const [
    createPlaylist,
    {
      isLoading: isLoadingCreatePlaylist,
      isSuccess: isSuccessCreatePlaylist,
      isError: isErrorCreatePlaylist,
      data: { data: createData } = {},
    },
  ] = useCreatePlaylistMutation();

  // label APPROVE PLAYLIST
  const [
    approvePlaylist,
    { data: approveData, isLoading: isLoadingApprovePlaylist },
  ] = useApprovePlaylistMutation();

  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  // label CREATE PLAYLIST
  useEffect(() => {
    if (isLoadingCreatePlaylist) {
      setStep(STEPS.LOADER);
    }
  }, [isLoadingCreatePlaylist]);

  useEffect(() => {
    if (isSuccessCreatePlaylist) {
      setStep(STEPS.VIDEO_PLAYER);
    }
  }, [isSuccessCreatePlaylist]);

  useEffect(() => {
    if (isErrorCreatePlaylist) {
      setStep(STEPS.LIST_SELECTED_ADS);
    }
  }, [isErrorCreatePlaylist]);

  const getComponentProps = useCallback(
    (step) => {
      let props = {};
      switch (step) {
        default:
        case STEPS.ADS_CATALOG:
          props = {
            headerProps: {
              title: "Select ads",
            },
            buttonProps: {
              onClick: () => setStep(STEPS.LIST_SELECTED_ADS),
            },
          };
          break;

        case STEPS.LIST_SELECTED_ADS:
          props = {
            headerProps: {
              title: "New playlist",
              deleteParams: ["search"],
              onBack: () => setStep(STEPS.ADS_CATALOG),
            },
            onSubmit: () => {
              const ads = getValues(INPUT_FORM_ADS);
              const adsIds = ads.map(({ id }) => id);
              const data = {
                playlist: {
                  description: "description",
                  devices: [],
                },
                ads: adsIds,
              };
              createPlaylist(data);
            },
          };
          break;

        case STEPS.VIDEO_PLAYER:
          props = {
            headerProps: {
              title: "New playlist",
              onBack: () => setStep(STEPS.LIST_SELECTED_ADS),
            },
            videoUrl: createData?.video_url,
            submitButtonLabel: "Next",
            onSubmit: () => {
              setStep(STEPS.FORM);
            },
          };
          break;

        case STEPS.FORM:
          props = {
            headerProps: {
              title: "New Playlist",
              onBack: () => setStep(STEPS.VIDEO_PLAYER),
            },
            fieldName: INPUT_FORM_NAME,
            submitButtonLabel: "Save",
            submitButtonProps: {
              type: "submit",
              loading: isLoadingApprovePlaylist,
            },
            onSubmit: ({ name }) => {
              approvePlaylist({
                playlistId: createData?.id,
                name,
              })
                .unwrap()
                .then(() => setStep(STEPS.ASSIGN_SCREENS));
            },
          };

          break;

        case STEPS.ASSIGN_SCREENS:
          props = {
            headerProps: {
              title: "Screens",
              onBack: () => setStep(STEPS.FORM),
            },
            playlistId: approveData?.playlist?.id,
          };
          break;
        case STEPS.LOADER:
          props = {
            entity: ENTITIES.PLAYLIST,
          };
          break;
      }

      return props;
    },
    [
      createData?.id,
      createData?.video_url,
      isLoadingApprovePlaylist,
      approveData?.playlist?.id,
      getValues,
      createPlaylist,
    ]
  );

  const handleSelectChange = (ads) => {
    setValue(INPUT_FORM_ADS, ads);
  };

  return (
    <Component
      control={control}
      register={register}
      errors={errors}
      onChangeStep={setStep}
      onSelectAdChange={handleSelectChange}
      {...getComponentProps(step)}
    />
  );
};

export default New;
