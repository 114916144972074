import Title from "components/Title";

const EmptyState = () => {
  return (
    <div className="flex flex-1 flex-col justify-center items-center gap-16">
      <Title size="2xl">Let’s create your first Ad!</Title>
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 items-center">
          <div className="flex justify-center items-center w-10 h-10 bg-base-50 rounded-full border  border-base-200">
            1
          </div>
          <span className="text-xl">Select Template</span>
        </div>
        <div className="flex gap-2 items-center">
          <div className="flex justify-center items-center w-10 h-10 bg-base-50 rounded-full border  border-base-200">
            2
          </div>
          <span className="text-xl">Add products</span>
        </div>
        <div className="text-center">
          <span className="text-xl">Done!</span>
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
