import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import cx from "classnames";
import { v4 as uuidv4 } from "uuid";

import Skeleton from "ui/Skeleton";
import Chip from "ui/Chip";
import IconButton from "ui/IconButton";
import Button from "ui/Button";

import Title from "components/Title";
import Placeholder from "components/Playlist/components/Placeholder/Placeholder";

import { PLAYLIST_TYPES } from "constants/playlists";

import { ReactComponent as StarIcon } from "assets/icons/star.svg";
import { ReactComponent as MoreIcon } from "assets/icons/more.svg";

import { renderComponent } from "utils/components";
import { replaceParams } from "utils/router";

import pages from "router/links";

import styles from "./styles.module.scss";

const COUNT_ELEMENTS = 5;
const COUNT_SLICE = 1;

const ActionSection = ({ onClick }) => (
  <IconButton aria-label="more" onClick={onClick}>
    <MoreIcon />
  </IconButton>
);

const MainPlaylist = ({
  isDefault = false,
  name = "Quick Start",
  videos = [],
  content = null,
  contentProps,
  footerRightContent = ActionSection,
  footerRightContentProps = {},
  label,
  component,
  playerProps = {
    withPlayer: false,
    url: null,
  },
  ...props
}) => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const Component = component ?? "div";

  const countVideos = videos.length;
  const moreCount = countVideos - COUNT_ELEMENTS + COUNT_SLICE;
  const isShowPlaceholder = COUNT_ELEMENTS < countVideos;
  const { withPlayer, url: videoUrl } = playerProps;

  const openFullScreen = useCallback(() => {
    if (!withPlayer) {
      return;
    }
    const video = videoRef.current;

    if (!isVideoLoaded) {
      video.innerHTML = `<source src="${videoUrl}" type="video/mp4" />`;
      setIsVideoLoaded(true);
    }

    video.classList.remove("hidden");
    video.classList.add("flex");

    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) {
      // Firefox
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) {
      // IE/Edge
      video.msRequestFullscreen();
    }
    video.play();
  }, [isVideoLoaded, videoUrl, withPlayer]);

  useEffect(() => {
    const video = videoRef.current;

    const handleExitFullScreen = () => {
      if (
        !document?.fullscreenElement &&
        !document?.webkitIsFullScreen &&
        !document?.mozFullScreen &&
        !document?.msFullscreenElement
      ) {
        video.pause();
        video.currentTime = 0;
        video.classList.remove("flex");
        video.classList.add("hidden");
      }
    };

    document?.addEventListener("fullscreenchange", handleExitFullScreen);
    document?.addEventListener("webkitfullscreenchange", handleExitFullScreen); // Safari
    video?.addEventListener("webkitendfullscreen", handleExitFullScreen); // iOS Safari

    return () => {
      document?.removeEventListener("fullscreenchange", handleExitFullScreen);
      document?.removeEventListener(
        "webkitfullscreenchange",
        handleExitFullScreen
      );
      video?.removeEventListener("webkitendfullscreen", handleExitFullScreen);
    };
  }, []);

  const renderPlaylist = useMemo(
    () =>
      Array.from({ length: COUNT_ELEMENTS }, (_, index) => {
        const currentElement = index + COUNT_SLICE;

        if (
          isShowPlaceholder
            ? currentElement !== COUNT_ELEMENTS
            : index < videos.length
        ) {
          const img = videos[index];
          return (
            <div
              key={index}
              className="flex flex-1 rounded-[8px] overflow-hidden aspect-[9/16]"
            >
              {img ? <img src={img} alt="" /> : <Placeholder />}
            </div>
          );
        }
        if (isShowPlaceholder && currentElement === COUNT_ELEMENTS) {
          return <Placeholder.More key={index} count={moreCount} />;
        }
        return <Placeholder key={index} />;
      }),
    [isShowPlaceholder, videos, moreCount]
  );

  const handleEditDefaultPlaylist = () => {
    navigate({
      pathname: replaceParams(pages.playlistCreate.path, {
        type: PLAYLIST_TYPES.DEFAULT,
      }),
    });
  };

  return (
    <Component
      className={cx(
        "relative flex flex-col gap-3 px-1 pt-1 pb-3 rounded-[12px] overflow-hidden",
        {
          [styles.default]: isDefault,
        }
      )}
      {...props}
    >
      {/*label LABEL*/}
      {label && (
        <Chip
          label={label}
          color="primary-invert"
          size="small"
          className="absolute top-3 left-3"
        />
      )}
      {/*label VIDEOS*/}
      {!!content ? (
        renderComponent(content, contentProps)
      ) : (
        <div className="grid grid-cols-5 gap-1" onClick={openFullScreen}>
          {renderPlaylist}
          {withPlayer && (
            <video
              ref={videoRef}
              controls
              controlsList="nodownload"
              className="absolute hidden -z-10"
            >
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      )}

      {/*label NAME*/}
      <div
        className={cx("flex justify-between items-center py-1", {
          "px-3": isDefault,
        })}
      >
        <Title className="flex gap-2">
          {isDefault && <StarIcon className="text-black w-[16px]" />} {name}
        </Title>
        {(isDefault && (
          <Button
            variant="text"
            size="small"
            onClick={handleEditDefaultPlaylist}
          >
            Change
          </Button>
        )) ||
          renderComponent(footerRightContent, footerRightContentProps)}
      </div>
    </Component>
  );
};

MainPlaylist.Skeleton = () => (
  <div className="flex flex-col gap-3">
    <div className="grid grid-cols-5 gap-1">
      {Array.from({ length: 5 }, () => ({ uuid: uuidv4() })).map(({ uuid }) => (
        <Skeleton.Round
          key={uuid}
          sx={{
            aspectRatio: "9 / 16",
            width: "100%",
            height: "auto",
          }}
        />
      ))}
    </div>
    <Skeleton width="35%" sx={{ fontSize: "1rem" }} />
  </div>
);

export default MainPlaylist;
