import { useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { hideDrawer, showDrawer } from "store/ducks/ui/drawer";

import { replaceParams } from "utils/router";

import { PLAYLIST_ACTIONS } from "constants/drawer";
import { PLAYLIST_TYPES } from "constants/playlists";

import CommonTemplate from "templates/CommonTemplate";

import Section from "components/Section";
import Playlist from "components/Playlist";
import SkeletonWrapper from "components/SkeletonWrapper";
import ComponentFallbackRenderer from "components/ComponentFallbackRenderer";

import Button from "ui/Button";
import Switch from "ui/Switch";
import IconButton from "ui/IconButton";

import {
  useChangeStatusPlaylistMutation,
  useDeletePlaylistFromDeviceMutation,
  useGetPlaylistsQuery,
} from "services/playlists";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "assets/icons/more.svg";
import { ReactComponent as RenameIcon } from "assets/icons/rename.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

import pages from "router/links";

const Screen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { screenId } = useParams();

  const [changeStatus, { data, isLoading: isLoadingChangeStatus }] =
    useChangeStatusPlaylistMutation();

  const {
    data: { data: playlistsData = [], device_name, limit, page, total } = {},
    isFetching: isPlaylistsFetching,
    refetch,
  } = useGetPlaylistsQuery({
    device: screenId,
    // todo add in [infinite scroll] and change [page] to dynamic [page]
    page: 1,
  });

  const [deletePlaylist] = useDeletePlaylistFromDeviceMutation();

  const countPlaylists = playlistsData.length;

  const createPlaylistLink = useMemo(
    () =>
      replaceParams(pages.playlistCreate.path, {
        type: countPlaylists ? PLAYLIST_TYPES.NEW : PLAYLIST_TYPES.DEFAULT,
      }),
    [countPlaylists]
  );

  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    refetch();
  }, [refetch]);

  // set default checkbox
  useEffect(() => {
    const activePlaylist = playlistsData.find((playlist) => playlist.is_active);
    if (activePlaylist) {
      setActiveId(activePlaylist.id);
    }
  }, [playlistsData]);

  const menuItems = useMemo(
    () => [
      {
        icon: RenameIcon,
        label: "Rename",
        onClick: (e, { id }) => {
          navigate(
            replaceParams(pages.playlistEdit.path, {
              playlistId: id,
            })
          );
          dispatch(hideDrawer());
        },
      },
      {
        icon: DeleteIcon,
        label: "Delete on device",
        color: "text-error",
        onClick: (e, { id }) => {
          deletePlaylist({
            id,
            deviceId: screenId,
          });
          dispatch(hideDrawer());
        },
      },
    ],
    []
  );

  const handleChangeStatus = (playlistId, deviceId) => (e, value) => {
    setActiveId(value && playlistId);

    changeStatus({
      playlistId,
      deviceId,
      status: value,
    });
  };

  const handleOpenMenu = ({ id, name }) => {
    dispatch(
      showDrawer({
        content: PLAYLIST_ACTIONS,
        headerProps: {
          title: name,
        },
        data: {
          menuItems,
          id,
        },
      })
    );
  };

  return (
    <CommonTemplate
      headerProps={{
        title: device_name,
      }}
    >
      <ComponentFallbackRenderer
        data={playlistsData}
        isLoading={isPlaylistsFetching}
      >
        {(playlists) => (
          <div className="flex flex-1 flex-col justify-between gap-y-4 px-4 py-6">
            <div className="flex flex-col gap-12">
              <Section title="Device Management">
                {(isPlaylistsFetching
                  ? Array.from({ length: 5 }, () => ({ uuid: uuidv4() }))
                  : playlists
                ).map(({ id, name, previews = [] }) => (
                  <SkeletonWrapper
                    condition={isPlaylistsFetching}
                    Component={Playlist.Skeleton}
                  >
                    <Playlist
                      key={id}
                      name={name}
                      videos={previews}
                      footerRightContent={
                        <div className="flex items-center gap-3">
                          <Switch
                            checked={activeId === id}
                            onChange={handleChangeStatus(id, screenId)}
                          />
                          <IconButton
                            aria-label="more"
                            onClick={() => handleOpenMenu({ id, name })}
                          >
                            <MoreIcon />
                          </IconButton>
                        </div>
                      }
                    />
                  </SkeletonWrapper>
                ))}
              </Section>
            </div>
            <Button
              variant="contained"
              startIcon={<PlusIcon className="w-4" />}
              component={NavLink}
              classes={{ root: "sticky bottom-6" }}
              to={createPlaylistLink}
            >
              Add Playlists
            </Button>
          </div>
        )}
      </ComponentFallbackRenderer>
    </CommonTemplate>
  );
};

export default Screen;
