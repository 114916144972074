import { api } from "services/api";

const BASE_URL = "templates";

export const templatesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTemplates: build.query({
      query: ({
        page = 1,
        limit = 10,
        search,
        categories,
        productsCount,
        useDefault,
      } = {}) => ({
        url: BASE_URL,
        params: {
          page,
          limit,
          search,
          categories,
          products_count: productsCount,
          useDefault,
        },
      }),
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 1 || !arg.page) {
          currentCache.data = newItems.data;
        } else {
          currentCache.data.push(...newItems.data);
        }
        currentCache.page = newItems.page;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg.search !== previousArg?.search ||
          currentArg.categories !== previousArg?.categories ||
          currentArg.productsCount !== previousArg?.productsCount ||
          currentArg.page !== previousArg?.page
        );
      },
    }),
    getTemplatesList: build.query({
      query: ({ limit, search } = {}) => ({
        url: `${BASE_URL}/list`,
        params: { limit, search },
      }),
      providesTags: [{ type: "TemplateList", id: "LIST" }],
    }),
    getTemplatesByCategory: build.query({
      query: ({ categories, page = 1, limit = 10, search } = {}) => ({
        url: BASE_URL,
        params: {
          categories: categories,
          page,
          limit,
          search,
        },
      }),
      keepUnusedDataFor: 0,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 1) {
          currentCache.data = newItems.data;
        } else {
          currentCache.data.push(...newItems.data);
        }
        currentCache.page = newItems.page;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg.search !== previousArg?.search ||
          currentArg.page !== previousArg?.page
        );
      },
    }),
    getTemplatesRecently: build.query({
      query: ({ page = 1, limit = 10, search } = {}) => ({
        url: `${BASE_URL}/recently`,
        params: {
          page,
          limit,
          search,
        },
      }),
      keepUnusedDataFor: 0,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === 1) {
          currentCache.data = newItems.data;
        } else {
          currentCache.data.push(...newItems.data);
        }
        currentCache.page = newItems.page;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg.search !== previousArg?.search ||
          currentArg.page !== previousArg?.page
        );
      },
    }),
    getTemplate: build.query({
      query: (id) => `${BASE_URL}/${id}`,
    }),
    getTemplateFilters: build.query({
      query: () => `${BASE_URL}/filters`,
    }),
    viewTemplate: build.mutation({
      query: (id) => ({
        url: `${BASE_URL}/view/${id}`,
        method: "POST",
      }),
      invalidatesTags: () => [{ type: "TemplateList", id: "LIST" }],
    }),
  }),
});

export const {
  useGetTemplatesByCategoryQuery,
  useGetTemplatesRecentlyQuery,
  useGetTemplatesQuery,
  useGetTemplatesListQuery,
  useGetTemplateQuery,
  useGetTemplateFiltersQuery,
  useViewTemplateMutation,
} = templatesApi;
