import cx from "classnames";

const SIZES = {
  base: "base",
  "2xl": "2xl",
  xl: "xl",
};

const COMPONENT_MAP = {
  [SIZES.base]: { component: "h2", size: "text-base" },
  [SIZES["xl"]]: { component: "h3", size: "text-xl" },
  [SIZES["2xl"]]: { component: "h3", size: "text-2xl" },
};

const Title = ({ size = "base", children, className }) => {
  const Component = COMPONENT_MAP[size].component;

  return (
    <Component
      className={cx("font-medium", COMPONENT_MAP[size].size, className)}
    >
      {children}
    </Component>
  );
};

export default Title;
