import Button from "ui/Button";
import Skeleton from "ui/Skeleton";

const TemplateSelect = ({ id, name, img, onChangeTemplate }) => {
  return (
    <div className="flex gap-4 justify-between bg-base-50 rounded-2xl p-1">
      <div className="flex gap-4">
        <img
          src={img}
          alt="template"
          className="w-16 h-16 object-cover rounded-lg"
        />
        <div className="flex flex-col gap-1 justify-center">
          <span className="leading-none line-clamp-1"> {name}</span>
        </div>
      </div>
      <div className="flex justify-center items-center px-3">
        <Button
          variant="text"
          className="text-brand-500"
          onClick={onChangeTemplate}
        >
          Change
        </Button>
      </div>
    </div>
  );
};

TemplateSelect.Skeleton = () => <Skeleton.Round height={72} />;

export default TemplateSelect;
