import { Controller } from "react-hook-form";

import MediaCard from "components/MediaCard";

import Button from "ui/Button";
import Switch from "ui/Switch";

import { DEVICES_NAME, IS_CONNECT_NAME, IS_PLAY_NAME } from "../../helpers";

const Card = ({ title, control, setValue, index }) => (
  <MediaCard.Secondary
    title={title}
    ImgComponent={null}
    className="gap-5 p-4"
    contentClassName="w-fit"
    actionsClassName="flex flex-1 gap-5 items-center"
    ActionComponent={
      <>
        <div className="flex items-center gap-2">
          <span className="text-base-500 text-sm">Play</span>
          <Controller
            name={`${DEVICES_NAME}.${index}.${IS_PLAY_NAME}`}
            control={control}
            render={({ field, ...p }) => (
              <Switch
                {...field}
                checked={field.value}
                onChange={(e) => {
                  const newValue = e.target.checked;
                  field.onChange(newValue);

                  if (newValue) {
                    setValue(
                      `${DEVICES_NAME}.${index}.${IS_CONNECT_NAME}`,
                      true
                    );
                  }
                }}
              />
            )}
          />
        </div>

        <Controller
          name={`${DEVICES_NAME}.${index}.${IS_CONNECT_NAME}`}
          control={control}
          render={({ field }) => {
            const { value } = field;
            const buttonColor = value ? "secondary" : "primary";
            const buttonTextColor = value ? "text-base-1000" : "inherit";
            const buttonText = value ? "Disconnect" : "Assign";

            return (
              <Button
                size="small"
                variant="contained"
                color={buttonColor}
                sx={{
                  padding: "9px 20px !important",
                  borderRadius: 12,
                  width: 115,
                }}
                onClick={() => {
                  const newValue = !value;
                  field.onChange(newValue);

                  if (!newValue) {
                    setValue(`${DEVICES_NAME}.${index}.${IS_PLAY_NAME}`, false);
                  }
                }}
              >
                <span className={buttonTextColor}>{buttonText}</span>
              </Button>
            );
          }}
        />
      </>
    }
  />
);
export default Card;
