const pages = {
  home: {
    name: "home",
    label: "home",
    path: "/",
  },
  playlists: {
    name: "playlists",
    label: "Playlists",
    path: "/playlists",
  },
  playlistCreate: {
    name: "playlistCreate",
    label: "Playlists",
    path: "/playlists/create/:type",
  },
  playlistsAssign: {
    name: "playlistsAssign",
    label: "Playlists",
    path: "/playlists/assign/:playlistId?",
  },
  playlistEdit: {
    name: "playlistEdit",
    label: "Playlists",
    path: "/playlists/:playlistId/edit",
  },
  addNewProduct: {
    name: "addNewProduct",
    label: "Add Product manually",
    path: "/addNewProduct",
  },
  templates: {
    name: "templates",
    label: "Templates",
    path: "/templates",
  },
  templatesList: {
    name: "templatesList",
    label: "Templates",
    path: "/templates/:templatesId",
  },
  ads: {
    name: "ads",
    label: "My Ads",
    path: "/ads",
  },
  adCreate: {
    name: "adCreate",
    label: "My Ads",
    path: "/ads/create",
  },
  adEdit: {
    name: "adEdit",
    label: "My Ads",
    path: "/ads/:adId/edit",
  },
  selectProduct: {
    name: "selectProduct",
    label: "Select a Product",
    path: "/selectProduct",
  },
  scanner: {
    name: "scanner",
    label: "Scanner",
    path: "/scanner",
  },
  stores: {
    name: "stores",
    label: "Stores",
    path: "/stores",
  },
  store: {
    name: "store",
    label: "Store",
    path: "/stores/:storeId/:pageType?",
  },
  screen: {
    name: "screen",
    label: "Screen",
    path: "/stores/:storeId/:pageType?/screens/:screenId",
  },
  profile: {
    name: "profile",
    label: "Profile",
    path: "/profile",
  },
  login: {
    name: "login",
    label: "Log In",
    path: "/login",
  },
  404: {
    name: "404",
    path: "*",
  },
};

export default pages;

export const homeLinks = [
  pages.stores,
  pages.templates,
  pages.ads,
  pages.playlists,
];
